(function($) {
  $.fn.makeProductScrollBox = function() {
    if (this.length && !(this.hasClass('js-enabled-scrollbox'))) {
      var $scrollbox = this.find('.product-list'),
          $items = $scrollbox.find('.product-miniature'),
          $arrows = this.find('.scroll-box-arrows'),
          list_element = '.product-list-wrapper',
          list_item_element = '.product-miniature',
          auto_play = $scrollbox.data('autoscroll') || false;

      if (($scrollbox.width() + 20) < ($items.length * $items.outerWidth())) {
        this.addClass('js-enabled-scrollbox');

        $scrollbox.scrollbox({
          direction: 'h',
          distance: 'auto',
          autoPlay: auto_play,
          infiniteLoop: false,
          onMouseOverPause: auto_play,
          listElement: list_element,
          listItemElement: list_item_element,
        });
        $arrows.addClass('scroll');
        $arrows.find('.left').click(function () {
          $scrollbox.trigger('backward');
        });
        $arrows.find('.right').click(function () {
          $scrollbox.trigger('forward');
        });
        if ('ontouchstart' in document.documentElement) {
          $scrollbox.on('swipeleft', function() {
            $scrollbox.trigger('forward');
          });
          $scrollbox.on('swiperight', function() {
            $scrollbox.trigger('backward');
          });
        }
      }
    }
  };

  $.fn.makeFlexScrollBox = function(options) {
    var settings = $.extend({
      list: 'ul',
      items: 'li',
      arrows: '.scroll-box-arrows',
      autoPlay: false,
      onMouseOverPause: false,
      forceMakeScroll: false
    }, options );

    var $scrollbox = this,
        $items = $scrollbox.find(settings.items),
        $arrows = $scrollbox.next(settings.arrows);

    if ((($scrollbox.width() + 20) < ($items.length * $items.outerWidth())) || settings.forceMakeScroll) {
      $scrollbox.scrollbox({
        direction: 'h',
        distance: 'auto',
        autoPlay: settings.autoPlay,
        onMouseOverPause: settings.onMouseOverPause,
        infiniteLoop: false,
        listElement: settings.list,
        listItemElement: settings.items,
      });
      $arrows.addClass('scroll');
      $arrows.find('.left').click(function () {
        $scrollbox.trigger('backward');
      });
      $arrows.find('.right').click(function () {
        $scrollbox.trigger('forward');
      });
      if ('ontouchstart' in document.documentElement) {
        $scrollbox.on('swipeleft', function() {
          $scrollbox.trigger('forward');
        });
        $scrollbox.on('swiperight', function() {
          $scrollbox.trigger('backward');
        });
      }
    }
  };

  $.fn.runCountdown = function(options) {
    var settings = $.extend({
      new_specific_prices_to: '0000-00-00 00:00:00',
      data_controller_name: 'getfinaldate-controller',
      data_current_specific_prices_to: 'current-specific-prices-to',
      final_date: 'final-date'
    }, options );
    var $countdown_wrapper = this;

    if ($countdown_wrapper.length) {
      let getFinalDate_controller = $countdown_wrapper.data(settings.data_controller_name);
          
      if (settings.new_specific_prices_to != undefined && settings.new_specific_prices_to != '0000-00-00 00:00:00') {
        let current_specific_prices_to = $countdown_wrapper.find('[data-'+settings.data_current_specific_prices_to+']').data(settings.data_current_specific_prices_to),
            update_cd = 0;

        if (current_specific_prices_to == undefined) {
          update_cd = 1;
        } else {
          if (current_specific_prices_to != settings.new_specific_prices_to) {
            update_cd = 2;
          }
        }
        if (update_cd) {
          $.ajax({
            type: 'POST',
            url: getFinalDate_controller,
            data: {
              'ajax': true,
              'specific-prices-to': settings.new_specific_prices_to
            },
            success: function(result) {
              if (update_cd == 2) {
                $countdown_wrapper.fadeTo(0, 0);
              }
              
              $countdown_wrapper.html(result);
              
              let $new_cd = $countdown_wrapper.find('[data-'+settings.final_date+']'),
                  format = $new_cd.html();
              if ($new_cd.length) {
                $new_cd.countdown($new_cd.data(settings.final_date))
                  .on('update.countdown', function(event) {
                    if(event.offset.totalDays <= 0) {
                      format = '<time>%H:%M:%S</time>';
                    }
                    $new_cd.html(event.strftime(format));
                  })
                  .on('finish.countdown', function() {
                    $countdown_wrapper.addClass('expired');
                  });

                setTimeout(function() {
                  if (update_cd == 1) {
                    $countdown_wrapper.slideDown();
                  } else {
                    $countdown_wrapper.fadeTo(400, 1);
                  }
                }, 500);
              }
            },
            error: function(err) {
              console.log(err);
            }
          });
        }
      }
    }
  };
 
  $.fn.makeTouchSpin = function() {
    this.TouchSpin({
      verticalbuttons: true,
      verticalupclass: 'material-icons touchspin-up',
      verticaldownclass: 'material-icons touchspin-down',
      buttondown_class: 'btn btn-touchspin js-touchspin',
      buttonup_class: 'btn btn-touchspin js-touchspin',
      min: parseInt(this.attr('min'), 10),
      max: 1000000,
      step: parseInt((this.attr('step') || 1), 10)
    });
  };
}(jQuery));