function stickyHeader() {
    var header_width = $('#header .main-header > .container').width(),
        $stickyMenu = $('[data-sticky-menu]'),
        $mobileStickyMenu = $('[data-mobile-sticky]');
    if (typeof(varStickyMenu) !== 'undefined' && varStickyMenu && $stickyMenu.length && header_width > 540) {
        $stickyMenu.sticky({
            wrapperClassName: 'desktop-sticky-wrapper'
        });

        $('[data-sticky-cart]').html($('[data-sticky-cart-source]').html());
    }
    if (typeof(varMobileStickyMenu) !== 'undefined' && varMobileStickyMenu && $mobileStickyMenu.length && header_width <= 540) {
        $mobileStickyMenu.sticky({
            wrapperClassName: 'mobile-sticky-wrapper'
        });
    }
}

function scrollToTopButton() {
    var $sttb = $('[data-scroll-to-top]');
    $(window).scroll(function() {
        if ($(this).scrollTop() > 100) {
            $sttb.fadeIn();
        } else {
            $sttb.fadeOut();
        }
    });

    $sttb.find('a').smoothScroll({
        speed: 500,
    });
}

function loadPageWithProcessBar() {
    if (typeof(varPageProgressBar) !== 'undefined' && varPageProgressBar) {
        Pace.start();
    }
}

function loadSidebarNavigation() {
    $('[data-close-st-menu]').on('click', function(e){
        $('html').removeClass('st-menu-open st-effect-left st-effect-right');
    });

    $('#js-header-phone-sidebar').removeClass('js-hidden').html($('.js-header-phone-source').html());
    $('#js-account-sidebar').removeClass('js-hidden').html($('.js-account-source').html());
    $('#js-language-sidebar').removeClass('js-hidden').html($('.js-language-source').html());
    if ((typeof(varSidebarCart) !== 'undefined' && !varSidebarCart)) {
        $('#js-left-currency-sidebar').removeClass('js-hidden').html($('.js-currency-source').html());
    }

    $('[data-left-nav-trigger]').on('click', function(e){
        $('html').addClass('st-effect-left st-menu-open');
        return false;
    });
}

function loadSidebarCart() {
    if (prestashop.page.page_name !== 'checkout' && prestashop.page.page_name !== 'cart') {
        if (typeof(varSidebarCart) !== 'undefined' && varSidebarCart) {
            $('#js-cart-sidebar').removeClass('js-hidden').html($('[data-shopping-cart-source]').html());
            $('[data-shopping-cart-source]').addClass('js-hidden');
            $('#js-currency-sidebar').removeClass('js-hidden').html($('.js-currency-source').html());

            $('[data-sidebar-cart-trigger]').on('click', function(e){
                $('html').addClass('st-effect-right st-menu-open');
                return false;
            });
        }
    }
}

function handleCookieMessage() {
    var $cookieMsg = $('.js-cookieMessage');
    if ($cookieMsg.length) {
        setTimeout(function(){
            $cookieMsg.cookieBar({
                closeButton : '.js-cookieCloseButton',
                path: prestashop.urls.base_url.substring(prestashop.urls.shop_domain_url.length)
            });
        }, 2000);
    }
}

loadPageWithProcessBar();

$(document).ready(function() {
    stickyHeader();
    scrollToTopButton();
    loadSidebarNavigation();
    loadSidebarCart();
});
$(window).load(function() {
    handleCookieMessage();
});

$(window).ready(function() {
    $('img.js-lazy').lazyload({
        failure_limit: 9999
    });
});