function restartIframeInModalContent() {
  $('.js-extraFieldPopup').each(function() {
    var $iframes = $(this).find('iframe');
    $(this).on('hidden.bs.modal', function () {      
      $iframes.each(function() {
        $(this).attr('src', $(this).attr('src'));
      });
    });
  });
}

function switchCombination() {
  if ($('.js-product-combinations').length) {
    $('.js-product-variants').hide();
  }

  $('body').on('click', '.js-switch-cbnt', function(e){
    e.preventDefault();

    if (!$(this).hasClass('active')) {
      var groups = $(this).data('groups');
      for (var g in groups) {
        $('.product-variants [name="group['+g+']"]').val(groups[g]);
        $('.product-variants [name="group['+g+']"]').trigger('change');
      }
    }

    return false;
  });

  prestashop.on('updatedProduct', function (event) {
    if ($('.js-product-combinations').length) {
      $('.js-product-variants').hide();

      if (event && event.id_product_attribute) {
        $('.js-switch-cbnt').removeClass('active');
        $('.js-switch-cbnt[data-id-product-attribute='+event.id_product_attribute+']').addClass('active');
      }
    }
  });
}

function countdownSpecificPrices() {
  $('.js-countdown-wrapper').runCountdown({
    new_specific_prices_to: $('[data-specific-prices-to]').data('specific-prices-to')
  });

  prestashop.on('updatedProduct', function (event) {
    $('.js-countdown-wrapper').runCountdown({
      new_specific_prices_to: $('[data-specific-prices-to]').data('specific-prices-to')
    });
  });
}

$(document).ready(function() {
  restartIframeInModalContent();
  switchCombination();
  countdownSpecificPrices();
});